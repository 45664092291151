import { useTranslations } from 'next-intl'
import * as React from 'react'
import { IMask } from 'react-imask'

import { env } from '@/utils/envs'
import { array, dayjs } from '@/utils/etc'

import type { FieldInputProps } from './field-input'

export const INPUT_TYPES = [
  'text',
  'email',
  'password',
  'cel',
  'tel',
  'cel_tel',
  'cpf',
  'cpf_cnpj',
  'ein',
  'cnpj',
  'rg',
  'number',
  'numeric',
  'float',
  'cep',
  'url',
  'crea',
  'kwp',
  'digito',
  'date',
  'search',
  'percentage',
  'percentage0',
  'credencial',
  'ssn_ein',
  'zip-code',
  'ssn',
  'kwh',
] as const

export type InputTypes = (typeof INPUT_TYPES)[number]

const DEFAULT_MASK = /^[\P{Emoji_Presentation}]*$/gu

const realtypes: Record<InputTypes, string> = {
  text: 'text',
  email: 'email',
  password: 'password',
  cel: 'tel',
  cnpj: 'text',
  cpf: 'text',
  rg: 'text',
  number: 'text',
  numeric: 'text',
  float: 'text',
  tel: 'tel',
  cep: 'text',
  url: 'text',
  crea: 'text',
  kwp: 'text',
  cpf_cnpj: 'text',
  digito: 'text',
  date: 'text',
  search: 'text',
  percentage: 'text',
  percentage0: 'text',
  credencial: 'text',
  cel_tel: 'text',
  ein: 'text',
  ssn_ein: 'text',
  'zip-code': 'text',
  ssn: 'text',
  kwh: 'text',
}

export const useFieldInput = ({
  name,
  locale = env.LANGUAGE,
  children,
}: Pick<
  React.PropsWithChildren<FieldInputProps>,
  'name' | 'children' | 'locale'
>) => {
  const t = useTranslations()

  const id = React.useMemo(() => `${name}-f`, [name])

  const _children = array(children).filter((v) => !!v)

  const before = _children.filter(
    ({ props: { slot } }: any) => slot === 'before'
  )
  const after = _children.filter(({ props: { slot } }: any) => slot === 'after')

  const masks: Record<InputTypes, IMask.AnyMaskedOptions> = React.useMemo(
    () => ({
      email: { mask: DEFAULT_MASK },
      password: { mask: DEFAULT_MASK },
      search: { mask: DEFAULT_MASK },
      text: { mask: DEFAULT_MASK },
      url: { mask: DEFAULT_MASK },
      date: {
        mask: Date,
        pattern: { en: 'MM/`DD/`YYYY' }[locale] || 'DD/`MM/`YYYY',
        overwrite: true,
        autofix: true,

        format: (date: Date) => {
          return dayjs(date).format(
            { en: 'MM/DD/YYYY' }[locale] || 'DD/MM/YYYY'
          )
        },

        parse: (str: string) => {
          return dayjs(
            str,
            { en: 'MM/DD/YYYY' }[locale] || 'DD/MM/YYYY'
          ).toDate()
        },

        blocks: {
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          YYYY: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
          },
        },
      },
      cep: { mask: '00000-000' },
      cpf:
        locale === 'en' ? { mask: '00-0000000' } : { mask: '000.000.000-00' },
      cnpj: { mask: '00.000.000/0000-00' },
      rg: { mask: /^[A-Za-z0-9]*$/ },
      cel:
        locale === 'en'
          ? { mask: '(000) 000-0000' }
          : { mask: '(00) 00000-0000' },
      tel:
        locale === 'en'
          ? { mask: '(000) 000-0000' }
          : { mask: '(00) 00000-0000' },
      cel_tel: {
        mask: [
          { mask: locale === 'en' ? '(000) 000-0000' : '(00) 00000-0000' },
          { mask: locale === 'en' ? '(000) 000-0000' : '(00) 00000-0000' },
        ],
      },
      digito: { mask: '0' },
      number: { mask: Number },
      numeric: { mask: /^[0-9]*$/ },
      float: { mask: /^\d+?[.,]?(\d+)?$/ },
      crea: { mask: '0000000000' },
      kwp: {
        mask: 'num {kWp}',
        autofix: true,
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            thousandsSeparator: locale === 'en' ? ',' : '.',
            radix: locale === 'en' ? '.' : ',',
            mapToRadix: [',', '.'],
            normalizeZeros: true,
            padFractionalZeros: true,
            signed: false,
          },
        },
      },
      percentage: {
        mask: 'num{%}',
        autofix: true,
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            thousandsSeparator: locale === 'en' ? ',' : '.',
            radix: locale === 'en' ? '.' : ',',
            mapToRadix: [',', '.'],
            normalizeZeros: true,
            padFractionalZeros: true,
            signed: true,
          },
        },
      },
      percentage0: {
        mask: 'num{%}',
        autofix: true,
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: locale === 'en' ? ',' : '.',
            radix: locale === 'en' ? '.' : ',',
            mapToRadix: [',', '.'],
            normalizeZeros: true,
            padFractionalZeros: true,
            signed: true,
          },
        },
      },
      cpf_cnpj: {
        mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }],
      },
      credencial: {
        mask: [
          { mask: '000.000.000-00' },
          { mask: '00-0000000' },
          { mask: '000-00-0000' },
          { mask: '00.000.000/0000-00' },
          { mask: DEFAULT_MASK },
        ],
      },
      ein: { mask: '00-0000000' },
      ssn_ein: { mask: [{ mask: '000-00-0000' }, { mask: '00-0000000' }] },
      'zip-code': { mask: /^[0-9]*$/ },
      ssn: { mask: '000-00-0000' },
      kwh: {
        mask: 'num {kWh}',
        autofix: true,
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            thousandsSeparator: env.LANGUAGE === 'pt-br' ? '.' : ',',
            radix: env.LANGUAGE === 'pt-br' ? ',' : '.',
            mapToRadix: [',', '.'],
            normalizeZeros: true,
            padFractionalZeros: true,
            signed: false,
          },
        },
      },
    }),
    [locale]
  )

  const defaultPlaceholders: Record<InputTypes, string> = React.useMemo(
    () => ({
      text: locale === 'en' ? 'Example' : 'Exemplo',
      email: t('proposal.placeholder_example_email'),
      password: '',
      cel: locale === 'en' ? '(123) 456-7890' : '(12) 99345-6789',
      tel: locale === 'en' ? '(123) 456-7890' : '(12) 93534-6789',
      cpf: '123.456.789-10',
      cnpj: '12.345.678/0001-91',
      rg: t('proposal.placeholder_document2'),
      number: '123',
      numeric: '123',
      float: '123.45',
      cep: '12345-678',
      url: t('proposal.placeholder_email'),
      crea: '1.234.567',
      kwp: '4,00 kWp',
      cpf_cnpj: t('proposal.placeholder_cpf_cnpj'),
      digito: '0',
      date: 'dd/mm/aaaa',
      search: t('proposal.placeholder_search_here'),
      percentage: '0,00%',
      percentage0: '0%',
      credencial: t('proposal.placeholder_example_email'),
      cel_tel: locale === 'en' ? '(123) 456-7890' : '(12) 93456-7891',
      ein: '00-0000000',
      ssn_ein: 'SSN or EIN number',
      'zip-code': 'Zip code',
      ssn: '123-45-6789',
      kwh: '100 kWh',
    }),
    [locale, t]
  )

  return {
    masks,
    id,
    before,
    after,
    realtypes,
    defaultPlaceholders,
  }
}
